<template>
  <div id="initiate-process">
    <Sheet
      :value="value"
      no-padding
      :width="formWidth"
      :has-footer="true"
      @input="closeInitiateProcessSheet"
    >
      <!-- title -->

      <template #title> {{ headerTitle }} </template>

      <template #actions>
        <BaseActionButton
          is-flat
          :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
          class="q-mr-sm"
          @click="toggleWidth"
        />
      </template>

      <div id="content-wrapper" class="row">
        <div v-if="formId && form" id="form-view" class="col">
          <!-- form -->
          <BaseScrollbar height="calc(100vh - 115px)">
            <RenderForm
              :form-id="formId"
              :panels="panels"
              :secondary-panels="secondaryPanels"
              :form-settings="formSettings"
              :form-model="formModel"
              :save-action.sync="saveAction"
              :workflow-form-model.sync="workflowFormModel"
              :workflow-model.sync="workflowModel"
              :is-readonly="formEditAccess"
              :form-edit-controls="formEditControls"
              :readonly-controls="readonlyControls"
              :form-visibility-access="formVisibilityAccess"
              :form-secure-controls="formSecureControls"
              :enable-controls="_enableControls"
              :check-list.sync="checkList"
            />

            <div v-if="signList.length" id="approvalSignature" class="q-pa-md">
              <div class="title q-mb-sm">Approval Signature</div>

              <BaseSeparator class="q-my-sm" />

              <div class="row">
                <template v-for="sign in signList">
                  <div :key="sign.id" class="col-3 q-mb-sm">
                    <img class="imgCenter col" :src="signLink(sign.id)" />
                    <div class="text row">
                      <span class="col-12 text-bold">{{ sign.stage }}</span>
                      <span class="col-12">{{ sign.createdByEmail }}</span>
                      <span class="col-12">{{
                        $day.format(sign.createdAt)
                      }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <SignatureField
              v-if="userSignature"
              v-model="signImg"
              label="Signature"
              class="q-pa-md"
            />
          </BaseScrollbar>
        </div>
        <!-- v-if="!isGenerateNumber"  -->
        <div v-if="!isGenerateNumber" class="col-auto q-mr-sm leftBorder">
          <template v-for="tab in tabs">
            <BaseActionButton
              :key="tab.id"
              v-tooltip.left="tab.label"
              is-flat
              no-border
              :icon="tab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(tab.value)"
            >
              <span v-if="tab.count" class="badge">{{ tab.count }} </span>
            </BaseActionButton>
          </template>
        </div>
      </div>

      <template #footer>
        <div v-if="actions.length" class="footer row">
          <BaseButton
            v-if="isGenerateNumber"
            label="Generate Number"
            class="q-ml-md"
            color="secondary"
            @click="validateRequest('Generate Number')"
          />
          <template v-if="!isGenerateNumber">
            <template v-for="action in actions">
              <BaseButton
                :key="action"
                :label="action"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
            </template>
          </template>
        </div>
      </template>
    </Sheet>

    <Modal
      v-model="documentGeneratedModal"
      width="30vw"
      height="60vh"
      has-footer
      @input="documentGeneratedModal = false"
    >
      <!-- title -->

      <template #title> New Document Number Generated</template>

      <!-- ... -->

      <template #default>
        <div>
          Find the generated document by selecting company, Department, and
          Document Type with version for a unique document number
          <b>{{ documentNumber }}</b> <br /><br />
          <b>Action1:</b> Copy the assigned Document Number provided below and
          include it on your document. <br /><br />
          <b>Action2:</b> Complete the process by uploading the document, now
          tagged with the Document Number, for approval.
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Copy Document Number"
          color="primary"
          icon="eva-clipboard-outline"
          class="q-mr-md"
          @click="copyDocumentNumber"
        />
        <BaseButton
          label="Continue to Upload"
          icon="eva-upload-outline"
          color="secondary"
          @click="proceedUpload"
        />
      </template>
    </Modal>

    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="processId && attachmentList.length ? true : false"
      :upload="true"
      no-padding
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <div
          v-if="processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 30 seconds
        </div>
        <BaseActionButton
          v-if="processId"
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getAttachments()"
        />
      </template>

      <template #upload>
        <div
          v-if="attachmentList.length === 0"
          class="hint"
          @click="
            {
              rowIndex = null;
              $refs.attachments.click();
            }
          "
        >
          <BaseIcon
            name="eva-cloud-upload-outline"
            size="32px"
            color="blue"
            class="icon"
          />

          <div class="title text-blue">UPLOAD FILES</div>
          <div class="description">Click here to choose files and upload</div>
        </div>
      </template>

      <template v-if="processId && attachmentList.length" #subActions>
        <CheckboxField
          v-model="selectAll"
          class="col-auto q-pt-sm q-pb-sm"
          @input="fileSelectAll"
        />
        Select All

        <BaseActionButton
          v-tooltip.top="'mail share'"
          :is-disabled="!selectedFileCount"
          icon="mdi-email-send-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          class="q-ml-sm"
          @click="documentShare"
        />

        <BaseActionButton
          v-tooltip.top="'merge documents (only PDF)'"
          :is-disabled="selectedFileCount > 1 && isSelectedPDF ? false : true"
          icon="mdi-file-document-multiple-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          @click="documentMerge"
        />
      </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 200px)">
          <input
            ref="attachments"
            type="file"
            class="hidden"
            @change="uploadWflowAttachments"
          />
          <template v-for="row in checkList">
            <template v-if="row.name">
              <div v-if="!row.attach" :key="row.uid" class="checkList">
                <div class="row">
                  <div class="col-auto">
                    <BaseIcon name="mdi-file-check-outline" color="primary" />
                  </div>
                  <div class="col q-ml-sm">
                    <div class="row">
                      <div class="col-12">
                        {{ row.name }}
                      </div>
                      <div v-if="row.required" class="col-12 q-mt-sm">
                        <q-chip outline color="red" class="chips small">
                          Required
                        </q-chip>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="col-12" @click="attachTemplate(row.uid)">
                      <q-chip
                        outline
                        color="primary"
                        class="chips primary-hover medium"
                      >
                        <q-avatar
                          icon="eva-upload-outline"
                          class="q-mr-sm"
                          color="primary"
                          text-color="white"
                        />
                        Upload
                      </q-chip>
                    </div>
                    <div
                      v-if="row.template"
                      class="col-12"
                      @click="download(row.id)"
                    >
                      <q-chip
                        outline
                        color="secondary"
                        class="chips secondary-hover medium"
                      >
                        <q-avatar
                          icon="eva-download-outline"
                          class="q-mr-sm"
                          color="secondary"
                          text-color="white"
                        />
                        Download
                      </q-chip>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else :key="row.uid">
                <template v-for="file in attachmentFilterCheckList(row.name)">
                  <div :key="file.id" class="attachmentList">
                    <div class="title row">
                      <div v-if="processId" class="col-auto">
                        <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                        <template v-if="file.loading">
                          <BaseIcon
                            name="mdi-loading"
                            color="secondary"
                            class="q-mr-sm loading"
                          />
                        </template>
                        <template v-else>
                          <BaseIcon
                            :name="
                              file.checked
                                ? 'eva-checkmark-circle-2'
                                : 'eva-radio-button-off-outline'
                            "
                            color="secondary"
                            class="q-mr-sm"
                            @click="file.checked = !file.checked"
                          />
                        </template>
                      </div>
                      <div v-else>
                        <template v-if="file.loading">
                          <BaseIcon
                            name="mdi-loading"
                            color="secondary"
                            class="q-mr-sm loading"
                          />
                        </template>
                      </div>
                      <div class="col-auto">
                        <FileIcon
                          :mime-type="fileType(file.name)"
                          class="mini-avatar q-mr-sm"
                        />
                      </div>
                      <div class="col ellipsis" @click="openFile(file)">
                        {{ file.name }}
                      </div>
                      <div v-if="!file.loading" class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Download'"
                          icon="eva-download-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Download')"
                        />
                      </div>
                      <div v-if="!file.loading" class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Print'"
                          icon="eva-printer-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Print')"
                        />
                      </div>
                    </div>

                    <div class="meta row">
                      <div class="col row">
                        <!-- created at -->

                        <div>{{ $day.format(file.createdAt) }}</div>

                        <!-- ... -->

                        <!-- created by -->

                        <template v-if="file.createdBy">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ file.createdByEmail }}</div>
                        </template>

                        <!-- ... -->

                        <!-- size -->

                        <template v-if="file.size">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ fileSize(file.size) }}</div>
                        </template>

                        <!-- ... -->
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Metadata'"
                          icon="eva-file-text-outline"
                          color="secondary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="getFileData(file)"
                        />
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'delete'"
                          icon="eva-trash-2-outline"
                          color="red"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="attachmentDelete(file)"
                        />
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Re-Upload'"
                          icon="mdi-file-upload"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="
                            {
                              reuploadFile = file;
                              reupload(file.name);
                            }
                          "
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>
          <template v-for="file in attachmentList">
            <template v-if="isCheckList(file.name)">
              <div :key="file.id" class="attachmentList">
                <div class="title row">
                  <template v-if="file.loading">
                    <BaseIcon
                      name="mdi-loading"
                      color="secondary"
                      class="q-mr-sm loading"
                    />
                  </template>
                  <div v-if="processId" class="col-auto">
                    <BaseIcon
                      :name="
                        file.checked
                          ? 'eva-checkmark-circle-2'
                          : 'eva-radio-button-off-outline'
                      "
                      color="secondary"
                      class="q-mr-sm"
                      @click="file.checked = !file.checked"
                    />
                  </div>
                  <div class="col-auto">
                    <FileIcon
                      :mime-type="fileType(file.name)"
                      class="mini-avatar q-mr-sm"
                    />
                  </div>
                  <div class="col ellipsis" @click="openFile(file)">
                    {{ file.name }}
                  </div>
                  <div v-if="!file.loading" class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Download'"
                      icon="eva-download-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Download')"
                    />
                  </div>
                  <div v-if="!file.loading" class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Print'"
                      icon="eva-printer-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Print')"
                    />
                  </div>
                </div>

                <div class="meta row">
                  <div class="col row">
                    <!-- created at -->

                    <div>{{ $day.format(file.createdAt) }}</div>

                    <!-- ... -->

                    <!-- created by -->

                    <template v-if="file.createdBy">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ file.createdByEmail }}</div>
                    </template>

                    <!-- ... -->

                    <!-- size -->

                    <template v-if="file.size">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ fileSize(file.size) }}</div>
                    </template>

                    <!-- ... -->
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Metadata'"
                      icon="eva-file-text-outline"
                      color="secondary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="getFileData(file)"
                    />
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'delete'"
                      icon="eva-trash-2-outline"
                      color="red"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="attachmentDelete(file)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>
    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      custom-height="30"
      no-padding
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <template #actions>
        <div
          v-if="processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 30 seconds
        </div>

        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getComments()"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 200px)">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail || row.externalCommentsby
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-1'
              "
              class="q-ma-sm"
            />
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div class="col q-pa-sm borderTop">
        <div class="row">
          <SingleChoiceField
            v-model="commentAction"
            :options="commentActions"
            :options-per-line="2"
            class="col q-mb-sm actions"
          />
        </div>
        <div class="row">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm commentAction"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm commentAction"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="
        {
          saveAction = '';
          workflowFormModel = '';
          advancedCommentsSheet = false;
        }
      "
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <template>
          <SingleChoiceField
            v-model="commentAction"
            :options="commentActions"
            :options-per-line="2"
            class="actions q-mr-sm noborder"
          />
        </template>
        <BaseButton
          label="Post"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action.id)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myAttachmentFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>

    <MetaDataEdit
      :value="showMetaDataEditModal"
      :file="workflow"
      :repository-field="repositoryField"
      :file-data="fileData"
      title="Index"
      :columns="formFields"
      :modal-value="workflowModel"
      :upload-filename="uploadFilename"
      @close="showMetaDataEditModal = false"
      @save="saveMetaData"
    />

    <FileDetails v-model="fileDetailsSheet" :file-data="fileData"></FileDetails>

    <EmailSharing
      v-model="emailSharingSheet"
      :repository-id="repositoryId"
      :file-list.sync="selectedFileList"
    ></EmailSharing>
  </div>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import {
  repository,
  form,
  custom,
  workflow,
  uploadAndIndex,
  menu,
} from "@/api/factory.js";
import { copyToClipboard } from "quasar";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import Modal from "@/components/common/popup/Modal.vue";
import ListItem from "@/components/common/ListItem.vue";
import MetaDataEdit from "@/views/repositories/views/repository-browse-and-trash/components/components/MetaDataEdit";
import axios from "axios";
import formatBytes from "@/helpers/format-bytes.js";
import FileIcon from "@/components/common/FileIcon.vue";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
} from "@/helpers/file-format.js";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import FileDetails from "./components/FileDetails";
import EmailSharing from "@/components/common/EmailSharing.vue";

export default {
  name: "InitiateProcess",

  components: {
    Sheet,
    RenderForm,
    Modal,
    ListItem,
    MetaDataEdit,
    TextAreaField,
    TextBuilderField,
    SingleChoiceField,
    CheckboxField,
    FileIcon,
    FileDetails,
    EmailSharing,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    itemFile: {
      type: Object,
      required: true,
    },

    repositoryId: {
      type: [Number, String],
      required: true,
    },

    repositoryField: {
      type: Array,
      default: () => [],
    },

    repositoryDetails: {
      type: Object,
      default: () => [],
    },

    workflow: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      headerTitle: "",
      formWidth: "100vw",
      processId: 0,
      transactionId: 0,
      formEntryId: 0,
      isGenerateNumber: false,
      documentNumber: "",
      actions: [],
      saveAction: "",
      formId: "",
      form: null,
      formFields: [],
      formModel: {},
      workflowFormModel: {},
      signList: [],
      formEditAccess: false,
      formEditControls: [],
      readonlyControls: [],
      formVisibilityAccess: true,
      formSecureControls: [],
      docWarning: "",
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
      ],
      userSignature: false,
      checkList: [],
      documentGeneratedModal: false,
      commentsList: [],
      attachmentList: [],
      attachmentSheet: false,
      commentsSheet: false,
      commentText: "",
      advancedCommentsSheet: false,
      advancedCommentText: "",
      fileSheet: false,
      selectedFile: {},
      fileDetailsSheet: false,
      emailSharingSheet: false,
      selectAll: false,
      selectedFileList: [],
      fileData: [],
      isLoading: false,
      commentActions: [
        {
          id: this.$nano.id(),
          label: "Internal",
          value: 2,
        },
        {
          id: this.$nano.id(),
          label: "Public",
          value: 1,
        },
      ],
      commentAction: 2,
      showMetaDataEditModal: false,
      selectedUploadFile: {},
      workflowModel: {},
      documentLoad: null,
      documentLoadList: [],
      documentActionList: [
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      commentsLoad: null,
      attachmentLoad: null,
      reuploadFile: {},
      generatePDF: 0,
      documentRequired: false,
      uploadFilename: "",
    };
  },

  computed: {
    isMaximized() {
      return this.formWidth === "100vw";
    },

    panels() {
      if (!this.form) {
        return [];
      }
      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.form) {
        return [];
      }
      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.form) {
        return {};
      }
      return this.form.settings;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      if (this.panels) {
        this.panels.forEach((panel) => {
          if (panel.fields.length) {
            let panelFields = panel.fields.filter(
              (field) =>
                field.type === "SINGLE_SELECT" ||
                field.type === "SINGLE_CHOICE" ||
                field.type === "MULTIPLE_CHOICE" ||
                field.type === "MULTI_SELECT"
            );
            if (panelFields.length) {
              panelFields.forEach((row) => {
                if (row.settings.validation.enableSettings) {
                  if (row.settings.validation.enableSettings.length) {
                    let controls = [];
                    row.settings.validation.enableSettings.forEach((tblrow) => {
                      if (tblrow.controls.length) {
                        controls.push({
                          value: tblrow.value,
                          childControls: tblrow.controls,
                        });
                        tblrow.controls.forEach((control) => {
                          if (controls.indexOf(control) < 0) {
                            listControls.push(control);
                          }
                        });
                      }
                    });
                    if (controls.length) {
                      enableControls.push({
                        id: row.id,
                        parentControls: controls,
                      });
                    }
                  }
                }
              });
            }
          }
        });
      }

      return {
        listControls: listControls,
        controls: enableControls,
      };
    },

    selectedFileCount() {
      let count = this.attachmentList.filter((item) => item.checked).length;
      return count;
    },

    isSelectedPDF() {
      let filePDF = true;
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() !== "pdf") {
          filePDF = false;
        }
      });
      return filePDF;
    },
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.formId = "";
        this.form = null;
        if (Object.keys(this.workflow).length) {
          this.isGenerateNumber = false;
          let origin = location.origin;
          if (
            (origin === "http://52.172.32.88" ||
              origin === "https://demo.ezofis.com" ||
              origin === "http://localhost:8080") &&
            this.workflow.id === 2092
          ) {
            this.isGenerateNumber = true;
          } else if (
            (origin === "https://edmsuat.sobhaapps.com" ||
              origin === "http://localhost:8080") &&
            this.workflow.id === 55
          ) {
            this.isGenerateNumber = true;
          }
          this.readWorkflow();
        }
      },
    },

    workflowFormModel: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowFormModel && this.saveAction) {
          if (this.isGenerateNumber) {
            this.documentNumberGenerate();
          } else {
            this.checkRequiredDocument();
          }
        }
      },
    },

    attachmentSheet: {
      immediate: true,
      handler() {
        if (this.attachmentSheet && this.processId) {
          this.attachmentLoad = setInterval(() => {
            this.getAttachments();
          }, 30000);
        } else {
          clearInterval(this.attachmentLoad);
        }
      },
    },

    commentsSheet: {
      immediate: true,
      handler() {
        if (this.commentsSheet && this.processId) {
          this.commentsLoad = setInterval(() => {
            this.getComments();
          }, 30000);
        } else {
          clearInterval(this.commentsLoad);
        }
      },
    },

    formFields: {
      deep: true,
      immediate: true,
      handler() {
        this.formEditAccess = false;
        if (Object.keys(this.itemFile).length && this.formFields.length) {
          this.formEditAccess = true;
          let fieldList = JSON.parse(this.itemFile.fieldlist);
          this.formFields.forEach((field) => {
            if (field.label !== "Document No") {
              let fields = fieldList.find((row) => row.fieldId == field.label);
              if (fields) {
                this.formModel[field.name] = fields.value;
              }
            }
          });
        }
      },
    },
  },

  methods: {
    toggleWidth() {
      this.formWidth = this.formWidth === "100vw" ? "50vw" : "100vw";
    },

    async copyDocumentNumber() {
      await copyToClipboard(this.documentNumber);
      this.$alert.success(`Document Number Copied`);
    },

    proceedUpload() {
      this.documentGeneratedModal = false;
      this.attachmentSheet = true;
    },

    readWorkflow() {
      this.actions = [];
      this.formId = "";
      this.commentsList = [];
      this.attachmentList = [];
      if (Object.keys(this.itemFile).length === 0) {
        this.formModel = {};
      }
      this.signList = [];
      this.formEditAccess = false;
      this.formEditControls = [];
      this.readonlyControls = [];
      this.formVisibilityAccess = true;
      this.formSecureControls = [];
      let wflowJson = JSON.parse(this.workflow.flowJson);
      let startBlock = wflowJson.blocks.find((block) => block.type === "START");
      if (startBlock) {
        if (startBlock.settings.initiateMode === "MANUAL") {
          this.headerTitle = `${startBlock.settings.label}`;
          let rules = wflowJson.rules.find(
            (rule) => rule.fromBlockId === startBlock.id
          );
          if (rules) {
            this.actions.push(rules.proceedAction);
          }
        }
        if (startBlock.settings.formEditAccess) {
          if (startBlock.settings.formEditAccess === "") {
            this.formEditAccess = true;
            this.formEditControls = [];
          } else if (startBlock.settings.formEditAccess === "FULL") {
            this.formEditAccess = false;
            this.formEditControls = [];
          } else if (startBlock.settings.formEditAccess === "PARTIAL") {
            this.formEditAccess = false;
            this.formEditControls = startBlock.settings.formEditControls;
          }
        }
        if (startBlock.settings.formVisibilityAccess) {
          this.formVisibilityAccess = true;
          this.formSecureControls = [];
        } else {
          this.formVisibilityAccess = false;
          this.formSecureControls = startBlock.settings.formSecureControls;
        }

        if (startBlock.settings.fileSettings) {
          if (startBlock.settings.fileSettings.fileCheckList.length) {
            this.checkList = startBlock.settings.fileSettings.fileCheckList;
            this.checkList.forEach((row) => {
              row.uid = this.$nano.id();
              row.attach = false;
            });
            this.docWarning = startBlock.settings.fileSettings.partial;
          }
        }

        this.generatePDF = startBlock.settings.generatePDF ? 1 : 0;
        this.documentRequired = startBlock.settings.documentRequired || false;
      }

      if (this.workflow.formId) {
        this.formId = String(this.workflow.formId);
        this.getForm();
      }
    },

    validateRequest(action) {
      this.saveAction = action;
    },

    checkRequiredDocument() {
      if (this.documentRequired && this.attachmentList.length === 0) {
        this.saveAction = "";
        this.workflowFormModel = {};
        this.$alert.warning(
          "A minimum of one document is required for submission"
        );
        return;
      }
      let docRequired = this.checkList.filter(
        (row) => row.required && !row.attach
      );
      if (docRequired.length) {
        if (this.docWarning) {
          this.alertModal = true;
        } else {
          this.$alert.warning("Mandatory documents required");
        }
        this.saveAction = "";
        this.workflowFormModel = {};
      } else {
        this.processRequest();
      }
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    closeCommentsSheet() {
      this.closeCommentsSheet = false;
    },

    closeInitiateProcessSheet() {
      this.isGenerateNumber = true;
      this.tabs.find((tab) => {
        tab.count = 0;
      });
      this.commentsList = [];
      this.attachmentList = [];
      this.formModel = {};
      this.processId = 0;
      this.documentNumber = "";
      this.transactionId = 0;
      this.formEntryId = 0;
      this.$emit("update:itemFile", {});
      this.$emit("input", false);
    },

    async showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        this.attachmentSheet = true;
      } else if (tab === "COMMENTS") {
        this.commentsSheet = true;
      }
    },

    isCheckList(name) {
      let list = this.checkList.filter(
        (row) =>
          row.name === name.substring(0, name.lastIndexOf(".")) ||
          row.name === name.substring(0, name.lastIndexOf(".")).split("_")[0]
      );
      if (list.length) {
        return false;
      }
      return true;
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          let type = 1;
          if (
            this.repositoryDetails.fieldsType === "STATIC" &&
            this.workflow.initiatedBy === "FORM"
          ) {
            type = 2;
          }
          let domainURL = "";
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              domainURL =
                "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
            } else if (origin === "https://trial.ezofis.com") {
              domainURL =
                "https://demo.ezofis.com/TrialPDFViewer/web/viewer.html";
            } else if (origin === "https://app.ezofis.com") {
              domainURL =
                "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
            } else {
              domainURL = `${origin}/PDFViewer/web/viewer.html`;
            }
          } else if (origin === "http://52.172.32.88") {
            domainURL = "http://52.172.32.88/DocsViewer/index.html";
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}`;
          }
          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=${type}`;
        } else {
          let action = "";
          action = "&action=all";
          let domainURL = "";
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              action = "";
              domainURL =
                "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
            } else if (
              origin === "https://trial.ezofis.com" &&
              !otherFilePDFSupport(ext)
            ) {
              domainURL = "https://trial.ezofis.com/docsviewer/index.html";
            } else if (origin === "https://app.ezofis.com") {
              domainURL =
                "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
            } else {
              domainURL = `${origin}/PDFViewer/web/viewer.html`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            domainURL = "http://52.172.32.88/DocsViewer/index.html";
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}${action}`;
        }
      }
    },

    openFile(fileObj) {
      if (!fileObj.loading) {
        this.selectedFile = fileObj;
        this.fileSheet = true;
      } else {
        this.$alert.info("Please wait to complete the uploading");
      }
    },

    fileSelectAll() {
      this.attachmentList = this.attachmentList.map((item) => {
        return {
          ...item,
          checked: this.selectAll,
        };
      });
    },

    reupload(filename) {
      filename = filename.substr(0, filename.lastIndexOf(".")).split("_")[0];
      this.checkListRowIndex = this.checkList.find(
        (row) => row.name === filename
      )?.uid;
      this.$refs.attachments.click();
    },

    attachmentFilterCheckList(name) {
      return this.attachmentList.filter(
        (row) =>
          row.name.substring(0, row.name.lastIndexOf(".")) === name ||
          row.name.substring(0, row.name.lastIndexOf(".")).split("_")[0] ===
            name
      );
    },

    async getForm() {
      this.formFields = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await form.getForm(this.formId);
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.form = JSON.parse(payload.formJson);
        const panels = [...this.form.panels, ...this.form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        let fields = [];
        for (const panel of panels) {
          fields.push(...panel.fields);
        }
        fields.forEach((field) => {
          if (
            field.type !== "DIVIDER" &&
            field.type !== "LABEL" &&
            field.type !== "PARAGRAPH" &&
            (field.label || field.type === "TABLE") &&
            field.settings.general.visibility !== "DISABLE"
          ) {
            this.formFields.push({
              id: this.$nano.id(),
              label: field.label || field.type,
              name: field.id,
              value: field.id,
              type: field.type,
            });
          }
        });
      }
    },

    async documentNumberGenerate() {
      let inputData = {
        workflowId: this.workflow.id,
        repositoryId: this.repositoryId,
        itemId: this.itemFile.id,
        formData: {
          formId: this.formId,
          fields: this.workflowFormModel,
        },
        review: "",
        comments: this.commentsList,
        addonLink: false,
      };
      this.$store.commit("showLoadingBar");
      const { error, payload } = await custom.documentNumberGenerate(inputData);
      this.$store.commit("hideLoadingBar");
      this.saveAction = "";
      this.workflowFormModel = {};
      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        this.documentNumber = payload.autoGenDocNo;
        let documentNoField = this.formFields.find(
          (field) => field.label === "Document No"
        );
        if (documentNoField) {
          this.workflowModel[documentNoField.value] = this.documentNumber;
          this.processId = payload.processId;
          this.transactionId = payload.transactionId;
          this.formEntryId = payload.formEntryId;
        }
        this.isGenerateNumber = false;
        this.documentGeneratedModal = true;
      }
    },

    async processRequest() {
      let inputData = {};
      if (this.formId) {
        inputData = {
          workflowId: this.workflow.id,
          transactionId: this.transactionId,
          review: this.saveAction,
          formData: {
            formId: this.formId,
            formEntryId: this.formEntryId,
            fields: this.workflowFormModel,
          },
          hasFormPDF: this.generatePDF,
        };
      }
      this.saveAction = "";
      this.workflowFormModel = {};
      console.log(inputData);

      this.$store.commit("showLoadingBarSave");
      const { error } = await workflow.processTransaction(inputData);
      this.$store.commit("hideLoadingBarSave");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.signImg) {
        await this.saveProcessSignature();
      }

      this.$alert.success(`${this.documentNumber} Request Processed`);
      this.closeInitiateProcessSheet();
    },

    async saveProcessSignature() {
      let input = {
        signBinary: this.signImg.split(",")[1],
        fileType: "png",
      };
      const { error } = await workflow.saveProcessSignature(
        this.workflow.id,
        this.processId,
        this.transactionId,
        input
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.signImg = "";
    },

    async getComments() {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      const { error, payload } = await workflow.getProcessComments(
        this.workflow.id,
        this.processId
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }
    },

    async saveComment() {
      if (this.commentText) {
        if (this.processId) {
          const { error } = await workflow.insertProcessComment(
            this.workflow.id,
            this.processId,
            this.transactionId,
            {
              comments: this.commentText,
              showTo: this.commentAction,
            }
          );

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.commentText = "";
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.commentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
          this.commentText = "";
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.processId) {
          const { error } = await workflow.insertProcessComment(
            this.workflow.id,
            this.processId,
            this.transactionId,
            {
              comments: this.advancedCommentText,
              showTo: this.commentAction,
            }
          );

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.advancedCommentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
        }
      }
    },

    async uploadWflowAttachments(e) {
      if (this.repositoryDetails.fieldsType === "STATIC") {
        this.selectedUploadFile = e;
        if (this.attachmentList.length === 0) {
          this.uploadFilename = this.documentNumber + ".pdf";
        } else {
          this.uploadFilename = e.target.files[0].name;
        }
        this.showMetaDataEditModal = true;
      } else {
        this.uploadAttachments(e);
      }
    },

    async uploadAttachments(e, fields) {
      if (this.processId) {
        const file = e.target.files[0];
        this.$store.commit("showLoadingBar");
        const formData = new FormData();
        formData.append("workflowId", this.workflow.id);
        formData.append("repositoryId", this.repositoryId);
        formData.append("processId", this.processId);
        formData.append("transactionId", this.transactionId);
        if (fields) {
          formData.append("fields", fields);
        } else {
          formData.append("fields", "");
        }
        formData.append("file", file);
        let filename = e.target.files[0].name;
        if (this.checkListRowIndex) {
          const ext = filename.split(".").pop();
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/workflow/attachmentWithProcessId`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          const { status, data } = response;
          if (status === 200 && data) this.$alert.success(`Document attached`);
          else throw response;
          this.documentLoad = setInterval(() => {
            this.getAttachments();
            clearInterval(this.documentLoad);
          }, 15000);
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        this.$store.commit("hideLoadingBar");
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            if (row.uid === this.checkListRowIndex) {
              row.attach = true;
              return;
            }
          });
        }
        e.target.value = "";
        if (Object.keys(this.reuploadFile).length) {
          await this.attachmentDelete(this.reuploadFile);
          this.reuploadFile = {};
        }
      } else {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("repositoryId", this.repositoryId);
        if (fields) {
          this.fileData = JSON.parse(fields);
          formData.append("fields", fields);
        }
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.checkListRowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        } else {
          formData.append("filename", filename);
        }
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });

          const { status, data } = response;
          if (status !== 200) {
            throw "Invalid response";
          }
          let filesList = JSON.parse(data);
          this.$alert.success(`Document attached`);
          let fileName = this.repositoryField.reduce((max, curren) =>
            max.level > curren.level ? max : curren
          );
          let name = this.fileData.find((item) => item.name === fileName.name);

          let loading = false;
          if (ext.toLowerCase() !== "pdf") {
            loading = true;
            this.documentLoadList.push(Number(filesList.fileId));
            this.documentLoad = setInterval(() => {
              let file = this.attachmentList.find(
                (row) => this.documentLoadList.indexOf(row.id) > -1
              );
              if (file) {
                this.documentLoadList.splice(
                  this.documentLoadList.indexOf(file.id),
                  1
                );
                file.loading = false;
              }
              if (this.documentLoadList.length === 0) {
                clearInterval(this.documentLoad);
              }
            }, 10000);
          }

          this.attachmentList.push({
            id: Number(filesList.fileId),
            name: fields ? name.value + "." + ext : filename,
            createdAt: new Date().toISOString(),
            createdByEmail: this.$store.state.session.email,
            size: file.size,
            initiate: true,
            uid: this.checkListRowIndex,
            createdBy: this.$store.state.session.id,
            fileData: this.fileData,
            repositoryId: this.repositoryId,
            loading: loading,
          });
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
          if (this.checkList.length) {
            this.checkList.forEach((row) => {
              if (row.uid === this.checkListRowIndex) {
                row.attach = true;
                return;
              }
            });
          }
          e.target.value = "";
        } catch (error) {
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
      }
    },

    async getAttachments() {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      let count = this.attachmentList.length;
      const { error, payload } = await workflow.getAttachments(
        this.workflow.id,
        this.processId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        if (count !== payload.length) {
          this.attachmentList = payload.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
          //clearInterval(this.attachmentLoad);
        }
        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = this.attachmentList.length;
          }
        });
      } else {
        this.attachmentList = [];
      }
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          let filterAttach = this.attachmentList.filter((file) => {
            let filename = file.name.substr(0, file.name.lastIndexOf("."));
            if (row.name === filename || row.name === filename.split("_")[0]) {
              return file;
            }
          });
          if (filterAttach.length) {
            row.attach = true;
          } else {
            row.attach = false;
          }
        });
      }
    },

    async attachmentDelete(file) {
      const { error } = await uploadAndIndex.deleteUploadedFiles([file.id]);
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.attachmentList = this.attachmentList.filter(
        (row) => row.id !== file.id
      );
      this.checkList.forEach((row) => {
        if (row.uid === file.uid) {
          row.attach = false;
        }
      });
    },

    saveMetaData(modal) {
      let data = [];
      this.repositoryField.forEach((field) => {
        data.push({
          name: field.name,
          value: modal[field.name],
          type: field.dataType,
        });
      });
      this.uploadAttachments(this.selectedUploadFile, JSON.stringify(data));
    },

    fileMenuAction(fileObj, action) {
      this.selectedFile = fileObj;
      this.documentAction(action, true);
    },

    documentAction(action, viewPrint) {
      if (action === "Email_Sharing") {
        //
      } else if (action === "Print") {
        if (this.selectedFile.name) {
          if (viewPrint) {
            let link = "";
            if (this.selectedFile.initiate) {
              let type = 1;
              if (
                this.repositoryDetails.fieldsType === "STATIC" &&
                this.workflow.initiatedBy === "FORM"
              ) {
                type = 2;
              }
              link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/1`;
            } else {
              link = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
            }
            window.open(link, "_blank");
          } else {
            let ext = this.selectedFile.name.split(".").pop();
            if (pdfSupport(ext)) {
              localStorage.setItem("filePrint", true);
              localStorage.setItem("filePrintId", this.selectedFile.id);
            } else {
              this.$refs.myAttachmentFiles.contentWindow.print();
            }
          }
        }
        this.filePrintSession();
      } else if (action === "Download") {
        let link = "";

        if (this.selectedFile.initiate) {
          let type = 1;
          if (
            this.repositoryDetails.fieldsType === "STATIC" &&
            this.workflow.initiatedBy === "FORM"
          ) {
            type = 2;
          }
          link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/2`;
        } else {
          link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        }
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async filePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.selectedFile.repositoryId,
        itemId: this.selectedFile.id,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getFileData(file, data) {
      const { error, payload } = await repository.getFileData({
        repositoryId: file.repositoryId,
        itemId: file.id,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      if (file.fileData) {
        this.fileData = file.fileData;
      } else {
        this.fileData = payload;
      }
      if (data !== "file") this.fileDetailsSheet = true;
    },

    async documentMerge() {
      let fileIds = [];
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() === "pdf") {
          fileIds.push(item.id);
        }
      });
      let input = {
        ids: fileIds,
      };
      const { error } = await workflow.documentMerge(
        this.workflow.id,
        this.processId,
        this.transactionId,
        this.repositoryId,
        input
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success("Document merge process initiated");
    },

    async documentShare() {
      this.selectedFileList = [];
      this.attachmentList.forEach((item) => {
        if (item.checked) {
          this.selectedFileList.push({
            id: item.id,
            name: item.name,
          });
        }
      });
      this.emailSharingSheet = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#initiate-process {
  #sheet {
    #form-view {
      background-color: var(--body-bg-color);
      margin: auto;
      padding: 16px;

      .header {
        padding: 16px;
        border-radius: 4px;
        background-color: var(--component-bg-color);
        border-top: 4px solid var(--primary);
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .description {
          @extend .text-sm;
          color: var(--icon-color-inverted);
        }
      }

      .footer {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 0;
        bottom: 16px;
        width: 100%;

        .label {
          @extend .text-sm;
          color: var(--icon-color-inverted);
          margin-right: 4px;
        }

        img {
          height: 16px;
          width: auto;
        }
      }
    }
  }
}

#sheet {
  .border-bottom {
    height: 1px;
    border: 0;
    align-self: stretch;
    background-color: var(--divider-color);
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    height: 18px;
    margin-top: -20px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }
}

#sheet {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }
}

#sheet .attachmentList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .fileFrame {
  min-height: calc(100vh - 120px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }
}

#sheet .hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  border: 2px dotted;
  border-radius: 10px;
  background-color: #e0ebfa26;
  padding: 5px;

  .icon {
    color: var(--body-text-color-inverted);
  }

  .title {
    @extend .title-2;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .description {
    color: var(--icon-color);
    text-align: center;
  }
}
</style>
